<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/"
        },
        {
          text: "Starter page",
          active: true
        }
      ],
      loading: true
    };
  },
  created() {
      this.title= this.$t('menuitems.iot.gatewayinfo')
      this.items=[
        {
          text: this.$t('menuitems.iot.text'),
          href: "/"
        },
        {
          text: this.$t('menuitems.iot.gateway'),
          ref: "/iot/gateway"
        },
        {
          text: this.$t('menuitems.iot.gatewayinfo'),
          active: true
        }
      ]
      
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>